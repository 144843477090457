import ApiService from "@/core/services/api.service";
import JWTService from "@/core/services/jwt.service"

// Actions
export const GET_REGION = "getRegion";
export const GET_ONE_REGION = "getOneRegio";
export const CREATE_REGION = "createRegion";
export const UPDATE_REGION = "updateRegion";
export const DELETE_REGION = "deleteRegion";
export const CLEAR_REGION = "clearRegion";
export const SET_STATE = "setStateRegion";
export const SET_REGION_SELECTED = "setRegionSelected";

// Mutation
export const SET_REGION = "setRegion";
export const UPDATE_STATE = "updateStateUjian";
export const SET_ONE_REGION = "setOneRegion";
export const SET_ERROR = "setErrorRegion";

const baseURL = `${process.env.VUE_APP_API_BASE_URL}api/`
export const LINK = `${baseURL}export/scores?exam_id=`

export const GET_FILE_RESULT_EXAM = "getFileResultExam";
export const GET_FILE_RSULT_EXAM_CLASS = "getFileResultExamClass"

export const URL_EXPORT = `${baseURL}export/scores?`

const state = {
    list: [],
    region: [],
    errors: null,
};

const getters = {
    listRegion(state) {
        if (state.list.data === undefined) {
            return {
                data: [],
                total: 0
            }
        }

        return state.list;
    },
    currentRegion(state) {
        return state.region;
    },
};

const actions = {
    // eslint-disable-next-line no-unused-vars
    [GET_REGION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get('region/paginate?' + payload)
                .then(({
                    data
                }) => {
                    context.commit(SET_REGION, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message || 'terjadi kesalahan');
                    reject();
                });
        });
    },
    [GET_ONE_REGION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get("exams/" + payload)
                .then(({
                    data
                }) => {
                    context.commit(SET_ONE_REGION, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [CREATE_REGION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("region", payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [UPDATE_REGION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put("region/" + payload._id, payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [DELETE_REGION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete("region/" + payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message || 'terjadi kesalahan');
                    reject();
                });
        });
    },
    [SET_REGION_SELECTED](context, payload) {
        context.commit(SET_ONE_REGION, payload);
    },
};

const mutations = {
    [SET_REGION](state, data) {
        state.list = data;
    },
    [SET_ONE_REGION](state, data) {
        state.region = data;
    },
    [SET_ERROR](state, errors) {
        state.errors = errors;
    },
    [UPDATE_STATE](state, data) {
        Object.assign(state, {
            ...data,
        })
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};