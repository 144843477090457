import ApiService from "@/core/services/api.service";

// Actions
export const GET_SOAL = "getSoal";
export const GET_ONE_SOAL = "getOneSoal";
export const GET_FROM_BANK_SOAL = "getFromBankSoal";
export const CREATE_SOAL = "createSoal";
export const UPDATE_SOAL = "updateSoal";
export const DELETE_SOAL = "deleteSoal";
export const CLEAR_SOAL = "clearSoal";
export const GET_ONE_QUESTION = "getOneQuestion";
export const GET_TOPIC = "getTopicQuestion";

// Mutation
export const SET_SOAL = "setSoal";
export const SET_ONE_SOAL = "setOneSoal";
export const SET_ERROR = "setErrorSoal";

const state = {
    soalList: [],
    soal: {},
    errors: null
};

const getters = {
    currentSoalList(state) {
        return state.soalList;
    },
    currentSoal(state) {
        return state.soal;
    },
};

const actions = {
    // eslint-disable-next-line no-unused-vars
    [GET_SOAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`exams/${payload}`)
                .then(({ data }) => {
                    context.commit(SET_SOAL, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_ONE_SOAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`ujian/${payload.idUjian}/soal/${payload.id}`)
                .then(({ data }) => {
                    context.commit(SET_ONE_SOAL, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_FROM_BANK_SOAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`ujian/from-bank-soal`, payload)
                .then(({ data }) => {
                    // context.commit(SET_SOAL, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [CREATE_SOAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`question`, payload)
                .then(({ data }) => {
                    // context.commit(SET_SOAL, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [UPDATE_SOAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`question/${payload.id}`, payload)
                .then(({ data }) => {
                    // context.commit(SET_SOAL, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [DELETE_SOAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`ujian/${payload.idUjian}/soal/${payload.id}`)
                .then(({ data }) => {
                    // context.commit(SET_SOAL, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },

    [GET_ONE_QUESTION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`question/${payload}`)
                .then(({ data }) => {
                    context.commit(SET_ONE_SOAL, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_TOPIC](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`question/get-topic`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [CLEAR_SOAL](context) {
        context.commit(SET_ONE_SOAL, {})
    },
    [SET_ONE_SOAL](context, payload) {
        context.commit(SET_ONE_SOAL, payload)
    },
};

const mutations = {
    [SET_SOAL](state, soal_list) {
        state.soalList = soal_list;
    },
    [SET_ONE_SOAL](state, soal) {
        state.soal = soal;
    },
    [SET_ERROR](state, errors) {
        state.errors = errors;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
