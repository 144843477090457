import ApiService from "@/core/services/api.service";


export const SET_SELECTED_FOLDER = "SET_SELECTED_FOLDER";
export const SAVE_SELECTED_FOLDER = "SAVE_SELECTED_FOLDER";

export const CREATE_FOLDER = "CREATE_FOLDER";
export const DELETE_FOLDER = "DELETE_FOLDER";
export const UPDATE_FOLDER = "UPDATE_FOLDER";

export const GET_FOLDER = "GET_FOLDER";
export const SAVE_FOLDER = "SAVE_FOLDER";

export const STATE_LOADING = "STATE_LOADING";
export const STATE_LOADING_SUBMIT = "STATE_LOADING_SUBMIT_FILE";


export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const UPDATE_DOCUMENT_ACCESS = "UPDATE_DOCUMENT_ACCESS";
export const SET_PROGRESS = "SET_PROGRESS_UPLOAD";
export const UPDATE_DOCUMENT_ARCHIVE = "UPDATE_DOCUMENT_ARCHIVE";
export const SEARCH_DOCUMENT = "SEARCH_DOCUMENT";




export const OPEN_PREVIEW_PDF = "OPEN_PREVIEW_PDF";
export const SET_PREVIEW_PDF = "SET_PREVIEW_PDF";

export const GET_DOCUMENT = "GET_DOCUMENT";

export const ARCHIVE = "GET_ARCHIVE";
export const GET_ARCHIVE = "GET_API_ARCHIVE";
export const SET_ARCHIVE = "SET_ARCHIVE";
export const SET_SEARCH_FILE = "SET_SEARCH_FILE";


const endpoint = "document-file";
const state = {
    folderSelected: 'root',
    files: [],
    displayFiles: [],
    isLoading: false,
    isLoadingSubmit: false,
    is_archive: false,
    progress: 0,
    allFiles: [], // wwithout child 
    document: null
};

const getters = {
    currentSelectedFolder(state) {
        return state.folderSelected;
    },
    currentFiles(state) {
        return state.files;
    },
    currentDisplayFolder(state) {
        return state.displayFiles;
    },
    currentIsLoading(state) {
        return state.isLoading;
    },
    currentAllFiles(state) {
        return state.allFiles;
    },
    currentIsLoadingSubmit(state) {
        return state.isLoadingSubmit;
    },
    currentSubmitProgress(state) {
        return state.progress;
    },
    currentPreviewPDF(state) {
        return state.document;
    }
};

const actions = {
    [SET_SELECTED_FOLDER](context, payload) {
        context.commit(SAVE_SELECTED_FOLDER, payload);
    },
    [CREATE_FOLDER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${endpoint}`, payload)
                .then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    reject(response);
                });
        });
    },
    [DELETE_FOLDER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${endpoint}/` + payload)
                .then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    reject();
                });
        });
    },
    [GET_FOLDER](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit(STATE_LOADING, true)

            var url = `${endpoint}?is_archive=false`
            if (payload == "archive") {
                url = `${endpoint}?is_archive=true`
            }

            ApiService.get(`${url}`)
                .then(({
                    data
                }) => {
                    context.commit(SAVE_FOLDER, data.data);
                    context.commit(STATE_LOADING, false);
                    resolve();
                })
                .catch(({
                    response
                }) => {
                    context.commit(STATE_LOADING, false);
                    reject(response);
                });
        });
    },
    [UPDATE_FOLDER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${endpoint}/` + payload._id, payload)
                .then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    reject(response);
                });
        });
    },
    [UPDATE_DOCUMENT_ACCESS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${endpoint}/${payload._id}/update-document-access`, payload)
                .then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    reject(response);
                });
        });
    },
    [UPDATE_DOCUMENT_ARCHIVE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${endpoint}/${payload._id}/archive`, payload)
                .then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    reject(response);
                });
        });
    },
    [UPLOAD_DOCUMENT](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit(STATE_LOADING_SUBMIT, true);

            const formData = new FormData()
            for (let key in payload) {

                if (key == "access") {
                    for (var i = 0; i < payload[key].length; i++) {
                        formData.append('access[]', payload[key][i]);
                    }
                    continue;
                }

                formData.append(key, payload[key])
            }

            ApiService.post(`${endpoint}/upload-document`, formData, {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    context.commit(SET_PROGRESS, percentCompleted);
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(({
                    data
                }) => {
                    context.commit(SET_PROGRESS, 0);
                    context.commit(STATE_LOADING_SUBMIT, false)
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_PROGRESS, 0);
                    context.commit(STATE_LOADING_SUBMIT, false)
                    reject(response);
                });
        });
    },
    [OPEN_PREVIEW_PDF](context, payload) {
        context.commit(SET_PREVIEW_PDF, payload);
    },
    [GET_DOCUMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${endpoint}/` + payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    },
    [ARCHIVE](context, payload) {
        context.commit(SAVE_SELECTED_FOLDER, payload);
    },
    [GET_ARCHIVE](context) {
        return new Promise((resolve, reject) => {
            context.commit(STATE_LOADING, true)
            ApiService.get(`${endpoint}?is_arhive=true`)
                .then(({
                    data
                }) => {
                    context.commit(SAVE_FOLDER, data.data);
                    context.commit(STATE_LOADING, false);
                    resolve();
                })
                .catch(({
                    response
                }) => {
                    context.commit(STATE_LOADING, false);
                    reject(response);
                });
        });
    },
    [SEARCH_DOCUMENT](context, payload) {
        context.commit(SET_SEARCH_FILE, payload);
    }
};

const mutations = {
    [SAVE_SELECTED_FOLDER](state, data) {
        if (data.name == 'root') {
            state.folderSelected = data;
            state.displayFiles = state.files;
            return;
        }

        state.folderSelected = data;
        if (data.child !== undefined) state.displayFiles = data.child;
        else state.displayFiles = [];
    },
    [SAVE_FOLDER](state, data) {
        state.files = customFunction.setFormatedFolderStructure(data);
        state.displayFiles = state.files;
        state.allFiles = data;
    },
    [STATE_LOADING](state, data) {
        state.isLoading = data;
    },
    [STATE_LOADING_SUBMIT](state, data) {
        state.isLoadingSubmit = data;
    },
    [SET_PROGRESS](state, data) {
        state.progress = data;
    },
    [SET_PREVIEW_PDF](state, data) {
        state.document = data;
    },
    [SET_ARCHIVE](state, data) {
        state.is_archive = data;
    },
    [SET_SEARCH_FILE](state, data) {
        if (data != "") {
            state.displayFiles = state.allFiles.filter((x) => x.name.toLowerCase().includes(data.toLowerCase()))
        } else {
            state.displayFiles = customFunction.setFormatedFolderStructure(state.allFiles);
        }
    }
};

const customFunction = {
    setFormatedFolderStructure(data) {
        var response = data.filter((x) => x.parent_id == null);
        return response.map((x) => {
            if (x.type == "folder") {
                x.child = this.getDocumentFormater(x, data)
            }
            if (x.type == "file") {
                x.name = `${x.name}.${x.ext}`;
                x.child = this.getDocumentFormater(x, data)
            }
            return x;
        });
    },
    getDocumentFormater(item, data) {
        var tempFilter = data.filter((x) => x.parent_id == item._id);
        return tempFilter.map(child => {
            if (child.type == "folder") {
                child.child = this.getDocumentFormater(child, data);
            }
            if (child.type == "file") {
                // child.name = child.name.replaceAll(`.${child.ext}`,"");
                // child.name = `${child.name}.${child.ext}`;
                child.child = this.getDocumentFormater(child, data);
            }
            return child;
        })
    }
}


export default {
    state,
    actions,
    mutations,
    getters
};