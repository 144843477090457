// Mutation
export const SET_PAKET = "setpaket";
export const SET_ERROR = "setError";
export const STORE_PAKET = "storepaket";
export const GET_PAKET = "getPaket";
export const DELETE_PAKET = "deleteUjian";
export const GET_ONE_PAKET = "getOnePaket";
export const SET_ONE_PAKET = "setOnePaket";

export const REMOVE_QUESTION = "removeQuestion";
export const UPDATE_PAKET = "updatePaket";
export const ADD_QUESTION = "addQuestion";

export const SELECTED_TOPICS = "selectedTopics";
export const SET_SELECTED_TOPICS = "setSelectedTopics";


import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { data } from "jquery";



const state = {
    paketList: {

    },
    paket: {

    },
    tempPaket: null,
    topics: null,
    errors: null,
};

const getters = {
    currentPaketList(state) {
        return state.paketList;
    },
    currentPaket(state) {
        return state.paket;
    },
    currentSelectedTopic(state) {
        return state.topics;
    }
};

const actions = {

    [STORE_PAKET](context, payload) {

        const user = JSON.parse(JwtService.getToken())
        Object.assign(payload, {
            vendor_id: user.vendor_id
        })

        return new Promise((resolve, reject) => {
            ApiService.post(`question-pack`, payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject(response);
                });
        });
    },
    [GET_PAKET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get('question-pack/paginate?' + payload)
                .then(({
                    data
                }) => {
                    context.commit(SET_PAKET, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [DELETE_PAKET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete("question-pack/" + payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_ONE_PAKET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`question-pack/${payload}`)
                .then(({
                    data
                }) => {
                    context.commit(SET_ONE_PAKET, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject(response);
                });
        });
    },
    [REMOVE_QUESTION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`question-pack/remove/${payload.id}`, payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [UPDATE_PAKET](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put("question-pack/" + payload.id, payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [ADD_QUESTION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`question-pack/add/${payload.id}`, payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [SELECTED_TOPICS](context, payload) {
        context.commit(SET_SELECTED_TOPICS, payload);
    }
};


const mutations = {
    [SET_ERROR](state, errors) {
        state.errors = errors;
    },
    [SET_PAKET](state, data) {
        state.paketList = data;
    },
    [SET_ONE_PAKET](state, data) {
        state.paket = data;
    },
    [SET_SELECTED_TOPICS](state, data) {
        var temp = state.paket;
        state.paket = temp;
        state.topics = data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters,
};