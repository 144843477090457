import ApiService from "@/core/services/api.service";
import JWTService from "@/core/services/jwt.service"

// Actions
export const GET_UJIAN = "getUjian";
export const GET_ONE_UJIAN = "getOneUjian";
export const GET_QUESTION_RECAP = "getQuestionRecap";
export const GET_SOAL_ESSAY = "getSoalEssay";
export const GET_ONE_SOAL_ESSAY = "getOneSoalEssay";
export const CREATE_UJIAN = "createUjian";
export const STORE_PENILAIAN = "storePenilaianEssay";
export const NILAI_AKHIR = "nilaiAkhir";
export const UPDATE_UJIAN = "updateUjian";
export const DELETE_UJIAN = "deleteUjian";
export const CLEAR_UJIAN = "clearUjian";
export const SET_STATE = "setStateUjian";

export const UNASSIGN_CLASS_FROM_EXAM = "unassignClassFromExam"
export const ASSIGN_CLASS_TO_EXAM = "assignClassToExam"
export const GET_MY_UJIAN = "getMyUjian";
export const SET_MY_UJIAN = "setMyUjian";
export const EXAM_START = "examStart";
export const SET_EXAM_START = "setExamStart";
export const GET_MY_HISTORY_EXAM = "getMyHistoryExam"

export const GET_LIST_RESULT_EXAM = "getListResultExam"
export const SET_LIST_RESULT_EXAM = "setListResultExam"

export const STUDENT_RESULT_EXAM = "studentResultExam";
export const SET_STUDENT_RESULT_EXAM = "setStudentResultExam"

export const GET_ALL_PEGAWAI_ASSESSMENT = "getListPegawaiAssessment"
export const GET_ASSESSMENT_CHART = "getAssessmentChart"

// Mutation
export const SET_UJIAN = "setUjian";
export const SET_SOAL_ESSAY = "setSoalEssay";
export const SET_LIST_ASSESMENT = "setListAssesment"
export const UPDATE_STATE = "updateStateUjian";
export const SET_ONE_UJIAN = "setOneUjian";
export const SET_ONE_SOAL_ESSAY = "setOneSoalEssay";
export const SET_ERROR = "setErrorUjian";
export const SET_MY_HISTORY_EXAM = "setMyHistoryExam"

const baseURL = `${process.env.VUE_APP_API_BASE_URL}api/`
export const LINK = `${baseURL}export/scores?exam_id=`

export const GET_FILE_RESULT_EXAM = "getFileResultExam";
export const GET_FILE_RSULT_EXAM_CLASS = "getFileResultExamClass"

export const URL_EXPORT = `${baseURL}export/scores?`






const state = {
    ujianList: [],
    ujian: {},
    soalEssayList: [],
    soalEssay: {},
    errors: null,
    myUjian: {},
    attempt: false,
    historyExam: [],
    ujianResultList: [],
    studentResultExam: {},
    listAssesment: null
};

const getters = {
    currentUjianList(state) {
        if (state.ujianList.data === undefined) {
            return {
                data: [],
                total: 0
            }
        }

        return state.ujianList;
    },
    currentUjian(state) {
        return state.ujian;
    },
    currentSoalEssayList(state) {
        return state.soalEssayList;
    },
    currentSoalEssay(state) {
        return state.soalEssay;
    },
    currentMyUjian(state) {

        if (state.myUjian.data === undefined) {
            return {
                data: [],
                total: 0
            }
        }

        return state.myUjian;
    },
    currentAttemptExam(state) {
        return state.attempt;
    },
    currentUjianResult(state) {
        return state.ujianResultList;
    },
    currentStudentResultExam(state) {
        return state.studentResultExam;
    }
};

const actions = {
    // eslint-disable-next-line no-unused-vars
    [GET_UJIAN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get('exams/paginate?' + payload)
                .then(({
                    data
                }) => {
                    context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_ONE_UJIAN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get("exams/" + payload)
                .then(({
                    data
                }) => {
                    context.commit(SET_ONE_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject(response);
                });
        });
    },
    [GET_SOAL_ESSAY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`ujian/${payload.id_ujian}/essay`)
                .then(({
                    data
                }) => {
                    context.commit(SET_ONE_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_ONE_SOAL_ESSAY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`ujian/${payload.id_ujian}/essay/${payload.id_soal}`)
                .then(({
                    data
                }) => {
                    context.commit(SET_ONE_SOAL_ESSAY, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [STORE_PENILAIAN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`exams/update-point/${payload._id}`, payload.body)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [NILAI_AKHIR](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`ujian/${payload.id_ujian}/nilai-akhir`)
                .then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [CREATE_UJIAN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post("exams", payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [UPDATE_UJIAN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put("exams/" + payload._id, payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [DELETE_UJIAN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete("exams/" + payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_MY_UJIAN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`exams/my?` + payload)
                .then(({
                    data
                }) => {
                    context.commit(SET_MY_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [CLEAR_UJIAN](context) {
        context.commit(SET_ONE_UJIAN, {})
    },
    [SET_STATE](context, payload) {
        context.commit(UPDATE_STATE, payload)
    },
    [UNASSIGN_CLASS_FROM_EXAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`exams/unassign/class`, payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [ASSIGN_CLASS_TO_EXAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`exams/assign/class`, payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [EXAM_START](context, payload) {
        context.commit(SET_EXAM_START, payload)
    },
    [GET_MY_HISTORY_EXAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get("exams/my-result")
                .then(({
                    data
                }) => {
                    context.commit(SET_MY_HISTORY_EXAM, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_LIST_RESULT_EXAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get("exams/result/" + payload)
                .then(({
                    data
                }) => {
                    context.commit(SET_LIST_RESULT_EXAM, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [STUDENT_RESULT_EXAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`exams/result/${payload.exam_id}/${payload.employee_id}?${payload.params}`)
                .then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    reject(response);
                });
        });
    },
    [GET_FILE_RESULT_EXAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`export/scores?${payload.params}`, {
                responseType: 'blob'
            })
                .then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [GET_ALL_PEGAWAI_ASSESSMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`exams/${payload.id}/assessment?${payload.params}`)
                .then(({
                    data
                }) => {
                    context.commit(SET_LIST_ASSESMENT, data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    reject(response);
                });
        });
    },
    [GET_ASSESSMENT_CHART](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`exams/${payload.id}/assessment/chart?${payload.params}`)
                .then(({
                    data
                }) => {

                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    reject(response);
                });
        });
    },
    [GET_QUESTION_RECAP](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`exams/${payload}/question-recap`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    reject(response);
                });
        });
    },
};

const mutations = {
    [SET_SOAL_ESSAY](state, soal_list) {
        state.soalEssayList = soal_list;
    },
    [SET_ONE_SOAL_ESSAY](state, soal) {
        state.soalEssay = soal;
    },
    [SET_UJIAN](state, ujian_list) {
        state.ujianList = ujian_list;
    },
    [SET_ONE_UJIAN](state, ujian) {
        state.ujian = ujian;
    },
    [SET_MY_UJIAN](state, ujian) {
        state.myUjian = ujian;
    },
    [SET_ERROR](state, errors) {
        state.errors = errors;
    },
    [SET_EXAM_START](state, data) {
        state.currentAttemptExam = data;
    },
    [UPDATE_STATE](state, data) {
        Object.assign(state, {
            ...data,
        })
    },
    [SET_MY_HISTORY_EXAM](state, data) {
        state.historyExam = data;
    },
    [SET_LIST_RESULT_EXAM](state, data) {
        state.ujianResultList = data;
    },
    [SET_STUDENT_RESULT_EXAM](state, data) {
        state.studentResultExam = data;
    },
    [SET_LIST_ASSESMENT](state, data) {
        state.listAssesment = data;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};