import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import mapel from "./mapel.module";
import ujian from "./ujian.module";
import soal from "./soal.module";
import banksoal from "./banksoal.module";
import rekapnilai from "./rekapnilai.module";
import attempt from './attempt.module'
import localforage from "localforage";
import VuexPersistence from "vuex-persist";
import vendorModule from "./vendor.module";
import userModule from "./user.module";
import classModule from "./kelas.module";
import paket from './paket.module';
import region from './region.module';
import level from './level.module'
import FileManagement from './file.module';

Vue.use(Vuex);

localforage.config({
  driver: localforage.IndexedDB,
  name: 'SIUJI',
  version: 1.0,
  storeName: 'siuji'
});

const vuexPersist = new VuexPersistence({
  // strictMode: true, // This **MUST** be set to true
  key: 'attempt',
  storage: localforage,
  asyncStorage: true,
  modules: [
    'attempt'
  ]
  // reducer: (state) => ({ attempt: state.attempt.attempt }),
  // filter: (mutation) => mutation.type === 'dogBark'
})

export default new Vuex.Store({
  modules: {
    vendorModule,
    userModule,
    classModule,
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    mapel,
    ujian,
    soal,
    banksoal,
    rekapnilai,
    attempt,
    paket,
    region,
    level,
    FileManagement
  },
  plugins: [
    vuexPersist.plugin
  ]
});