import Vue from "vue";
import Router from "vue-router";
import store from '@/core/services/store/index.js'
import {
  getMenu
} from "@/core/menu.js"
import {
  VERIFY_AUTH
} from "@/core/services/store/auth.module";

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [{
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [{
          path: "/dashboard",
          name: "dashboard",
          meta: {
            requiresAuth: true
          },
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/region",
          name: "Region",
          component: () => import("@/view/pages/region/Region.vue")
        },
        {
          path: "/region/create",
          name: "region-create",
          component: () => import("@/view/pages/region/RegionForm.vue")
        },
        {
          path: "/region/:id/edit",
          name: "region-create",
          component: () => import("@/view/pages/region/RegionForm.vue")
        },
        {
          path: "/branch",
          name: "Branch",
          component: () => import("@/view/pages/branch/Branch.vue")
        },
        {
          path: "/Branch/create",
          name: "branch-create",
          component: () => import("@/view/pages/branch/BranchForm.vue")
        },
        {
          path: "/Branch/:id/edit",
          name: "branch-edit",
          component: () => import("@/view/pages/branch/BranchForm.vue")
        },
        {
          path: "/branch/:idKelas/assign/:role",
          name: "kelas-assign",
          component: () => import("@/view/pages/branch/AssignEmployee.vue")
        },
        {
          path: "/employee",
          name: "Employee",
          component: () => import("@/view/pages/employee/Employee.vue")
        },
        {
          path: "/employee/create",
          name: "employee-create",
          component: () => import("@/view/pages/employee/EmployeeForm.vue")
        },
        {
          path: "/employee/:id/edit",
          name: "employee-edit",
          component: () => import("@/view/pages/employee/EmployeeForm.vue")
        },
        {
          path: "/rekap-nilai",
          name: "rekap-nilai",
          component: () => import("@/view/pages/rekapnilai/RekapNilaiList.vue")
        },
        {
          path: "/rekap-nilai/:idUjian",
          name: "rekap-nilai-view",
          component: () => import("@/view/pages/rekapnilai/NilaiSiswaList.vue")
        },
        {
          path: "/bank-soal",
          name: "bank-soal",
          component: () => import("@/view/pages/banksoal/BankSoalList.vue")
        },
        {
          path: "/bank-soal/soal/create",
          name: "bank-soal-create",
          component: () => import("@/view/pages/soal/MultipleSoalForm.vue")
        },
        {
          path: "/bank-soal/:idSoal/edit",
          name: "bank-soal-edit",
          component: () => import("@/view/pages/soal/SoalEdit.vue")
        },
        {
          path: "/histori-ujian",
          name: "histori",
          component: () => import("@/view/pages/rekapnilai/HistoryUjian.vue")
        },
        {
          path: "/attempt",
          name: "ujian-attempt",
          component: () => import("@/view/pages/attempt/AttemptSoal.vue")
        },
        {
          path: "/preview/ujian/:idUjian",
          name: "ujian-attempt-preview",
          component: () => import("@/view/pages/attempt/AttemptPreview.vue")
        },
        {
          path: "/ujian",
          name: "ujian-list",
          component: () => import("@/view/pages/ujian/UjianList.vue")
        },
        {
          path: "/ujian/create",
          name: "ujian-create",
          component: () => import("@/view/pages/ujian/UjianForm.vue")
        },
        {
          path: "/ujian/:idUjian/soal",
          name: "soal",
          component: () => import("@/view/pages/soal/SoalList.vue")
        },
        {
          path: "/ujian/:idUjian/hasil",
          name: "soal",
          component: () => import("@/view/pages/ujian/UjianHasil.vue")
        },
        {
          path: "/ujian/:idUjian/edit",
          name: "soal",
          component: () => import("@/view/pages/ujian/UjianForm.vue")
        },
        {
          path: "/ujian/:id/rekap/soal",
          name: "soal",
          component: () => import("@/view/pages/ujian/rekap/Soal.vue")
        },
        {
          path: "/daftar-guru",
          name: "daftar-guru",
          component: () => import("@/view/pages/DaftarGuru.vue")
        },
        {
          path: "/mapel",
          name: "mapel",
          component: () => import("@/view/pages/MapelList.vue")
        },
        {
          path: "/mapel/:idKurikulum/ujian",
          name: "ujian",
          component: () => import("@/view/pages/ujian/UjianList.vue")
        },
        {
          path: "/mapel/:idKurikulum/ujian/create",
          name: "ujian-create",
          component: () => import("@/view/pages/ujian/UjianForm.vue")
        },
        {
          path: "/mapel/:idKurikulum/ujian/:idUjian/edit",
          name: "ujian-edit",
          component: () => import("@/view/pages/ujian/UjianForm.vue")
        },
        {
          path: "/ujian/:idUjian/penilaian",
          name: "penilaian",
          component: () => import("@/view/pages/rekapnilai/ListSoalEssay.vue")
        },
        {
          path: "/ujian/:idUjian/penilaian/:idStudent",
          name: "penilaian-soal",
          component: () => import("@/view/pages/rekapnilai/PenilaianSoalEssay.vue")
        },

        {
          path: "/mapel/:idKurikulum/ujian/:idUjian/soal/create",
          name: "soal-create",
          component: () => import("@/view/pages/soal/SoalForm.vue")
        },
        {
          path: "/mapel/:idKurikulum/ujian/:idUjian/soal/:idSoal/edit",
          name: "soal-edit",
          component: () => import("@/view/pages/soal/SoalForm.vue")
        },
        {
          path: "/vendor",
          name: "vendor",
          component: () => import("@/view/pages/vendor/VendorList.vue")
        },
        {
          path: "/vendor/create",
          name: "vendor-create",
          component: () => import("@/view/pages/vendor/VendorForm.vue")
        },
        {
          path: "/vendor/:vendorId/edit",
          name: "vendor-edit",
          component: () => import("@/view/pages/vendor/VendorForm.vue")
        },
        {
          path: "/guru",
          name: "guru",
          component: () => import("@/view/pages/guru/GuruList.vue")
        },
        {
          path: "/guru/create",
          name: "guru-create",
          component: () => import("@/view/pages/guru/GuruForm.vue")
        },
        {
          path: "/guru/:guruId/edit",
          name: "guru-create",
          component: () => import("@/view/pages/guru/GuruForm.vue")
        },
        {
          path: "/siswa",
          name: "Siswa",
          component: () => import("@/view/pages/siswa/SiswaList.vue")
        },
        {
          path: "/siswa/:studentId/edit",
          name: "Siswa-edit",
          component: () => import("@/view/pages/siswa/SiswaForm.vue")
        },
        {
          path: "/siswa/create",
          name: "Siswa-create",
          component: () => import("@/view/pages/siswa/SiswaForm.vue")
        },
        {
          path: "/kelas",
          name: "Kelas",
          component: () => import("@/view/pages/kelas/KelasList.vue")
        },
        {
          path: "/kelas/create",
          name: "Kelas",
          component: () => import("@/view/pages/kelas/KelasForm.vue")
        },
        {
          path: "/kelas/:idKelas/:type",
          name: "Kelas",
          component: () => import("@/view/pages/kelas/KelasForm.vue")
        },
        {
          path: "/kelas/:idKelas/assign/:role",
          name: "kelas-assign",
          component: () => import("@/view/pages/kelas/AssignForm.vue")
        },
        {
          path: "/paket-soal",
          name: "paket-soal",
          component: () => import("@/view/pages/paket/PaketList.vue")
        },
        {
          path: "/paket-soal/create",
          name: "paket-soal",
          component: () => import("@/view/pages/paket/PaketForm.vue")
        },
        {
          path: "/paket-soal/:idQuestion/edit",
          name: "paket-soal",
          component: () => import("@/view/pages/paket/PaketForm.vue")
        },
        {
          path: "/profile",
          name: "Profile",
          component: () => import("@/view/pages/profile/Profile.vue")
        },
        {
          path: "/summary-assesment/:id",
          name: "summary-assesment",
          component: () => import("@/view/pages/summary/SummaryAssesment.vue")
        },
        {
          path: "/level",
          name: "level",
          component: () => import("@/view/pages/level/ListLevel.vue")
        },
        {
          path: "/level/:id",
          name: "level-detail",
          component: () => import("@/view/pages/level/DetailLevel.vue")
        },
        {
          path: "/hak-akses",
          name: "hak-akses",
          component: () => import("@/view/pages/hak-akses/PilihHakAkses.vue")
        },
        {
          path: "/file-management",
          name: "file-management",
          component: () => import("@/view/pages/file_management/FileManagement.vue")
        },
        {
          path: "/file-management?type=archive",
          name: "file-management-archive",
          component: () => import("@/view/pages/file_management/FileManagement.vue")
        },
        {
          path: "/review-exam/:examId/employee/:employeeId",
          name: "review-exam",
          component: () => import("@/view/pages/ujian/ReviewExam.vue")
        },
        {
          path: "/preview/question-pack/:id",
          name: "ujian-attempt-preview",
          component: () => import("@/view/pages/attempt/AttemptPreviewQuestionPack.vue")
        },
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/Login"),
      children: [{
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/Login"),
          meta: {
            requiresAuth: false
          }
        },

        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/Login")
        }
      ]
    },
    {
      name: "forgot-password",
      path: "/forgot-password",
      component: () => import("@/view/pages/Forgot.vue"),
      meta: {
        requiresAuth: false
      }
    },
    {
      name: "aktivasi-akun",
      path: "/aktivasi-akun",
      component: () => import("@/view/pages/Activation.vue"),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/Error.vue")
    }
  ]
});

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, _, next) => {

  // if(!to.meta.requiresAuth && !store.getters["isAuthenticated"]) {
  //   next({ name: 'login'});
  // }

  if (!store.state.auth.isAuthenticated && to.path != "/login" && to.name != "forgot-password" && to.name != "aktivasi-akun"  && !to.meta.requiresAuth) {
    next({
      name: 'login'
    })
    return;
  } else {
    if (store.state.auth.isAuthenticated && to.path == "/login") {
      next({
        name: 'dashboard'
      })
      return;
    }

    if (store.getters["isAuthenticated"]) {
      const myMenu = getMenu(store.getters["currentUser"].role);
      // console.log(myMenu.filter(data => data.to.toLowerCase().includes(to.path)));
      const sepratePath = to.path.split("/");

      var path = "/dashboard";

      if (sepratePath.length > 0) {
        path = sepratePath[1];
      }

      if(store.getters["currentUser"].role === 'student' && store.getters["currentUser"].is_password_expired == 1 && to.path !== "/profile") {
        
        next('/profile');
        // window.location.reload();
        return;
      } 

      if (myMenu.filter(data => data.to.toLowerCase().includes(path)).length <= 0) {
        next("/dashboard");
        return;
      }



      if (store.getters["currentAttemptExam"]) {
        next("/attempt")
        return;
      }
    }
  }


  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next).catch((error) => {
    if (error.toString().includes('401')) {
      next("/login")
    }
  });

  next();


  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

})


export default router