// Mutation
export const SET_LEVEL = "setLevel";
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoading";


export const STORE_LEVEL = "storeLevel";
export const GET_LEVEL = "getLevel";
export const GET_ONE_LEVEL = "getOneLevel";
export const DELETE_LEVEL = "deleteLevel";
export const UPDATE_LEVEL = "updateLevel";
export const GET_INDUK_LEVEL = "getIndukLevel"
export const GET_LEVEL_ACCESS = "getLevelAccess"


import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";



const state = {
    levelList: {

    },
    errors: null,
    loadingSubmit: false
};

const getters = {
    currentLevelList(state) {
        return state.levelList;
    },
    loadingSubmitLevel(state) {
        return state.loadingSubmit;
    }
};

const actions = {

    [STORE_LEVEL](context, payload) {

        const user = JSON.parse(JwtService.getToken())
        Object.assign(payload, {
            vendor_id: user.vendor_id
        })

        return new Promise((resolve, reject) => {
            context.commit(SET_LOADING, true);
            ApiService.post(`level`, payload)
                .then(({
                    data
                }) => {
                    context.commit(SET_LOADING, false);
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_LOADING, false);

                    context.commit(SET_ERROR, response.data.message);
                    reject(response);
                });
        });
    },
    [GET_LEVEL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get('level/paginate?' + payload)
                .then(({
                    data
                }) => {
                    context.commit(SET_LEVEL, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [DELETE_LEVEL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete("level/" + payload)
                .then(({
                    data
                }) => {
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject();
                });
        });
    },
    [UPDATE_LEVEL](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit(SET_LOADING, true);
            ApiService.put("level/" + payload._id, payload)
                .then(({
                    data
                }) => {
                    context.commit(SET_LOADING, false);
                    // context.commit(SET_UJIAN, data.data);
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    context.commit(SET_LOADING, false);
                    reject(response);
                });
        });
    },
    [GET_INDUK_LEVEL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get('level/paginate?' + payload)
                .then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    reject(response);
                });
        });
    },
    [GET_ONE_LEVEL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`level/${payload}`)
                .then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    reject(response);
                });
        });
    },
    [GET_LEVEL_ACCESS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`level/access`)
                .then(({
                    data
                }) => {
                    resolve(data);
                })
                .catch(({
                    response
                }) => {
                    reject(response);
                });
        });
    },
};


const mutations = {
    [SET_ERROR](state, errors) {
        state.errors = errors;
    },
    [SET_LEVEL](state, data) {
        state.levelList = data;
    },
    [SET_LOADING](state, data) {
        state.loadingSubmit = data;
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};