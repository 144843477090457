export const getMenu = (role) => {
    const menu = [{
        to: '/dashboard',
        name: 'Dashboard',
        icon: 'media/svg/icons/Devices/Diagnostics.svg',
        role: 'admin,teacher,student,vendor,staff'
    },
    {
        title: 'Master Data',
        titleOnly: true,
        role: 'vendor,staff',
        to: '/1'
    },
    {
        to: '/employee',
        name: 'Pegawai',
        icon: 'media/svg/icons/General/User.svg',
        role: 'vendor,staff'
    },
    {
        to: '/level',
        name: 'Level Akses',
        icon: 'media/svg/icons/Text/Align-right.svg',
        role: 'vendor,staff'
    },
    {
        title: 'Assesment',
        titleOnly: true,
        role: 'vendor,staff',
        to: '/3'
    },
    {
        to: '/ujian',
        name: 'Daftar Ujian',
        icon: 'media/svg/icons/Communication/Clipboard-check.svg',
        role: 'vendor,staff'
    },
    {
        to: '/vendor',
        name: 'Vendor',
        icon: 'media/svg/icons/Files/File.svg',
        role: 'admin'
    },
    {
        to: '/mapel',
        name: 'Mata Pelajaran',
        icon: 'media/svg/icons/Home/Library.svg',
        role: ''
    },
    {
        to: '/histori-ujian',
        name: 'Riwayat Ujian',
        icon: 'media/svg/icons/General/Clipboard.svg',
        role: 'teacher,student'
    },
    {
        to: '/paket-soal',
        name: 'Paket Soal',
        icon: 'media/svg/icons/Home/Library.svg',
        role: 'vendor/teacher,staff'
    },
    {
        to: '/bank-soal',
        name: 'Bank Soal',
        icon: 'media/svg/icons/Code/Question-circle.svg',
        role: 'teacher,vendor,staff'
    },
    {
        to: '/profile',
        name: 'profile',
        icon: 'media/svg/icons/Home/Library.svg',
        role: 'teacher,student,vendor,admin,staff',
        show: false
    },
    {
        to: '/attempt',
        icon: 'media/svg/icons/Home/Library.svg',
        role: 'student',
        show: false
    }, {
        to: '/rekap-nilai',
        icon: 'media/svg/icons/General/Clipboard.svg',
        role: 'teacher,vendor,staff',
        show: false,
    },
    {
        to: '/preview',
        icon: 'media/svg/icons/General/Clipboard.svg',
        role: 'teacher,vendor,staff',
        show: false
    }, {
        to: '/export',
        icon: 'media/svg/icons/General/Clipboard.svg',
        role: 'teacher,vendor,staff',
        show: false
    },
    {
        to: '/summary-assesment',
        icon: 'media/svg/icons/General/Clipboard.svg',
        role: 'teacher,vendor,staff',
        show: false
    },
    {
        to: '/hak-akses',
        icon: 'media/svg/icons/General/Clipboard.svg',
        role: 'vendor,staff',
        show: false
    },
    {
        title: 'Manajemen File',
        titleOnly: true,
        role: 'vendor,student,staff',
        to: '/'
    },
    {
        to: '/file-management',
        name: 'Dokumen',
        icon: 'media/svg/icons/Text/Bullet-list.svg',
        role: 'teacher,vendor,student,staff'
    },
    {
        to: '/file-management?type=archive',
        name: 'Arsip',
        icon: 'media/svg/icons/Text/Bullet-list.svg',
        role: 'teacher,vendor,staff'
    },
    {
        to: '/review-exam',
        name: 'Review Exam',
        icon: 'media/svg/icons/Text/Bullet-list.svg',
        role: 'teacher,vendor,staff',
        show: false
    },
    ]

    return menu.filter(data => data.role.toLowerCase().includes(role))
}